@media screen and (min-width: 768px) {
  .emptyTitle {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .cardsContainer {
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin-bottom: 70px;
  }
}
